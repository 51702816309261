<template>
   <v-app>
      <!-- <home-system-bar /> -->

      <!-- <user-app-bar /> -->

      <home-view />
      <!-- class="grey darken-4" -->
      <notification />

      <!-- <user-footer /> -->

      <!-- <home-settings /> -->
   </v-app>
</template>

<script>
// import { flatten } from '@/utils/nirtara'
import { } from '@/utils/nirtara' // encrypt, decrypt
export default {
   name: 'UserLayout',

   components: {
      // UserAppBar: () => import('@/layouts/user/AppBar'),
      // UserFooter: () => import('@/layouts/user/Footer'),
      // HomeSettings: () => import('@/layouts/home/Settings'),
      // HomeSystemBar: () => import('@/layouts/home/SystemBar'),
      HomeView: () => import('@/layouts/home/View'),
      Notification: () => import('@/layouts/home/Notification'),
   },

   created () { // async
      /*
      // console.log(`created ( ${decrypt('e30')} = ${encrypt('{}')} )`, JSON.parse(decrypt(localStorage.getItem('nrm50web') || 'e30')))
      console.log('created (this.$route.params)', JSON.parse(JSON.stringify(this.$route.params)))
      if (this.$route.params.encrypt) {
         // https://192.168.1.101:6800/registration/62827bca9fd4508a9196ead7MjAyMi0wNS0yMA
         const string = this.$route.params.encrypt
         const data = { id: string.slice(0, 24), expiry: decrypt(string.slice(24)) }
         console.log('created (data)', JSON.parse(JSON.stringify(data)))
         // const date = this.$moment(data.expiry)
         // if (!data.id || data.id.length !== 24 || !data.expiry || !date.isValid() || !date.isSameOrAfter(this.$moment())) this.$router.push({ name: 'Home' })
         // then find activity based on id
      } else if (this.$route.params.id && this.$route.params.expiry) {
         // https://192.168.1.101:6800/registration/62827bca9fd4508a9196ead7/MjAyMi0wNS0yMA
         const data = { id: this.$route.params.id, expiry: decrypt(this.$route.params.expiry) }
         console.log('created (data)', JSON.parse(JSON.stringify(data)))
         // then find receptionist based on id
      } else {
      }
      */
      this.$waitSetting(zone => {
         this.$socket.on(`server/${this.$store.state.zone}/Message`, (data) => {
            /*
            if (data.Content && data.Content.length && data.Content[0].kind === 'Global') Object.assign(this.$store.state.setting.global, flatten(data.Content[0]))
            else
            */
            if (data.Server) {
               if (data.welcome) {
                  this.$store.commit('x-forwarded-for', data.Server[0].ip)
                  console.log(data.welcome)
               }
               // if (data.online) {
               //    const user = this.$store.state.user
               //    user.id === data.Server[0].id && data.online !== user.online && this.$store.dispatch('logout').then(() => { this.$route.name !== 'Home' && this.$router.push({ name: 'Home' }) })
               // }
            } else console.log(`created (server/${zone}/Message)`, data)
         })
      })
   },
   destroyed () {
      this.$socket.off(`server/${this.$store.state.zone}/Message`)
   },
}
</script>
